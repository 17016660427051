
import React from 'react';
import {useState,useEffect} from 'react';

import { useMemo } from 'react';
import axios from 'axios';

/* Need to: npm install react-router-dom */
import { BrowserRouter , Route , Link , Switch , Redirect } from 'react-router-dom';

import Navbar from "./components/Navbar/Navbar";

import LineChart from "./components/LineChart";
import PieChart from "./components/PieChart";
import Table from "./components/Table";

import ScrollToTop from "./ScrollToTop";

import Home from "./components/Home";
import Investments from "./components/Investments";
import Taxes from "./components/Taxes";
import About from "./components/About";
import Contact from "./components/Contact";
import './App.css';



/* ------------------------------------------------------------------------------- */
// Neat article on passing  data through react router: https://www.freecodecamp.org/news/a-complete-beginners-guide-to-react-router-include-router-hooks/
// Destructure {match:{params:{name}}} same as props.match.params.name
// https://reactgo.com/react-fragments: React fragment is a react component which helps to return the multiple child elements without using the extra dom nodes. Can write as <Fragment> or <>.
// {console.log(props)} allows you to see everything in props, including history, location and params.
// Or {props.match.params.name}. Near article https://dilshankelsen.com/how-to-use-url-parameters-in-react-router/ on all that is inside props.
// Doing history.push('/') goes directly to home. With '<Redirect to="/" />' would replace the page and user can't go back to it.!! */}
{/*
const Home = () => ( <h1>Home</h1> );
const About = ({match:{params:{name}}}) => ( <h1>About {name}</h1> );
const Abouting = (props) => (
	<> 
		{console.log(props)}
		<h1>Abouting {props.match.params.name}</h1>
	</> );
const Historing= ({history}) => (
	<>
		<h1>Historing</h1>
		{console.log(history)}
		<button onClick={() => history.push('/') } >Go home</button>
	</> );
const mr = "John Doe"
const mr_ing = "Johny Dy"
*/}
/* ------------------------------------------------------------------------------- */
// Neat article on how to pass data between a Parent Component and a Child Component: https://www.freecodecamp.org/news/pass-data-between-components-in-react/
// You pass data as property that childA can access. You pass a function as property that childB can access to send data back to Parent.
{/*
function ChildA({parentToChild}) {
	return(
		<>
		<div> -------------------------- </div>
		<div> {new Date().toLocaleString()} </div>
		<div>
		{parentToChild}
		</div>
		<div> -------------------------- </div>
		</>
	)
}
function ChildB({childToParent}) {
	const data = "This is data from Child Component to the Parent Component."
	return(
		<>
		<div> -------------------------- </div>
		<div> {new Date().toLocaleString()} </div>
		<div>
		{childToParent}
		</div>
		<div> <button onClick={ () => childToParent(data) } >Click Child</button> </div>
		<div> -------------------------- </div>
		</>
	)
}
function Parent() {
	const [dataA,setDataA] = useState('');
	const parentToChild = () => { setDataA("This is data from Parent Component to the Child Component."); }
	const [dataB,setDataB] = useState('');
	const childToParent = (childData) => { setDataB(childData) }
	return(
		<div>
		<ChildA parentToChild={dataA} />
		<div> {new Date().toLocaleString()} </div>
		<div>
		{dataB}
		</div>
		<div> <button onClick={() => parentToChild()}>Click Parent</button> </div>
		<ChildB childToParent={childToParent} />
		</div>
	)
}
*/}
/* ------------------------------------------------------------------------------- */

function App() {

  const [focus,setFocus] = useState('');
  const childToParent = (childData) => { setFocus(childData) }

  const [data,setData] = useState([]);

  const [dataTable,setDataTable] = useState([]);

  /* React.useMemo is used to create a table structure to ensure that the data isn't recreated on every render. */
  const tabledata = useMemo(
    () => [
      {
        // first group - Users
        Header: "Users",
        // group columns
        columns: [
          {
            Header: "Id",
            accessor: "id"
          },
          {
            Header: "Name",
            accessor: "name"
          },
          {
            Header: "User Name",
            accessor: "username"
          }

        ]
      },
      {
        // Second group - Contact Details
        Header: "Contact Details",
        // group columns
        columns: [
          {
            Header: "Phone",
            accessor: "phone"
          },
          {
            Header: "Email",
            accessor: "email"
          },
          {
            Header: "Website",
            accessor: "website"
          }
        ]
      }
    ],
    []
  );

  useEffect( () => {
	regenerateData();


	(async () => {
		const result = await axios("https://jsonplaceholder.typicode.com/users");
		setDataTable(result.data);
		console.log("Pulling from jason placeholder:")
		console.log(result.data);
	})();

  }, [])
  function regenerateData() {
	const chartData = [];

	for ( let i=0; i < 20; i++) {

		const value = Math.floor(Math.random() * i + 3);
		chartData.push({
			label: i,
			value,					/* Automatically does dictionary. */
			tooltipContent: `<b>x: </b>${i}<br><b>y: </b>${value}`, /*Bold b, Line break br.*/
		});
	}
	setData(chartData);

	console.log('Checking data:');
	console.log(data);
  }

  const dataPie = [{label: 'Apples', value: 10, tooltipContent: '<b>Apples</b><br><b>10</b>'},{label: 'Oranges', value: 20, tooltipContent: '<b>Oranges</b><br><b>20</b>'}]

  return (
	<BrowserRouter>



    <div className="App">





	<div className="App_top">
	<Navbar initialState={false} childToParent={childToParent} /> 
	</div>

	{/*	<div className="App_clear"></div> */}





	<div className="App_body">

	{/*
	<div className="DoIntegral">
	$$\int f(x) dx$$
	</div>
	<div className="DoIntegral">
	There is a nice green color as an option as well. Check website where you saw it and compare.
	For pages, download sp500, 10yr (maybe others) and vix from Fred. Maybe a few others more economic oriented as well.
	For pages, you can display graph of tax rates environment.
	Likely d3.
	</div>
	*/}



	



	{/* ScrollToTop is not pertinent at this point. Can be removed if needed. */}
	<ScrollToTop>
	<Switch>
	  {/* <Route path="/" exact render={ () => "" } />		*/}
		<Route path="/home" component={Home} />
		<Route path="/investments" component={Investments} />
		<Route path="/taxes" component={Taxes} />		
		<Route path="/about" component={About} />
		<Route path="/contact" component={Contact} />
	  	<Redirect to="/home" />
	  {/* <Route render={ () => <h1>404: page not found</h1>} />	*/}

	</Switch>
	</ScrollToTop>









	{/* www.slickcharts.com has very neat index information. */}

	{/*
	<button onClick={regenerateData}>Change Data</button>
	<LineChart data={data} width={400} height={300} />
	<Table columns={tabledata} data={dataTable} />
	<PieChart data={dataPie} outerRadius={200} innerRadius={50} />
	*/}


{/* Fonts imported in index.html from public folder. */}

{/*
	<div className="font_Lora">This is font Lora targetting titles.</div>
	<div className="font_Roboto">This is font Roboto targetting text.</div>
	<div className="font_Lato">This is font Lato as second option targetting lighter text.</div>
*/}

{/* {alert('Here are we passing data through react router!')} */}
	  	{/* Instead of using a tag and href, react router uses Link and to to, well, be able to switch between pages without reloading it. */}
	  	{/* <li><a href="/">Home</a></li> */}
		{/* <li><a href="/about">About</a></li> */}
{/* 
	<div>
	<nav>
	<ul>
		<li><Link to="/">Home</Link></li>
		<li><Link to={`/about/${mr}`}>About</Link></li>
		<li><Link to={`/abouting/${mr_ing}`}>Abouting</Link></li>
		<li><Link to={`/about/abc`}>About</Link></li>
		<li><Link to={`/abouting/abcing`}>Abouting</Link></li>
		<li><Link to="/historing">Historing</Link></li>
	</ul>
	</nav>
*/}
{/* Switch to tell react router to load only one route at a time. */}
{/* Exact so that it does not simply check if the path defined starts with "/" */}
{/* Adjusting its path so that parameter will be received as props from the component.*/}
{/* The Route component has render and component as properties. */}
{/* 
	<Switch>
		<Route path="/" exact component={Home} />		
		<Route path="/about/:name" component={About} />		
		<Route path="/abouting/:name" component={Abouting} />
		<Route path="/historing" component={Historing} />
		<Route render={ () => <h1>404: page not found</h1>} /> 
	</Switch>
	</div>
	<div>
	<Parent />
	</div>
*/}

	</div>

    </div>

	</BrowserRouter>
  );

{/* Pertinent to use rel when doing hyperlink: <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"		// The linked document will open in a new tab.
          rel="noopener noreferrer"	// Browser not to send an HTTP referrer header. Prevent an opener browsing context.
        >Learn React</a> */}

}

export default App;
