import  React , { useState } from 'react';

import './Contact.css'

const ContactForm = () => {
	const [status, setStatus] = useState("Submit");

	return (
		<div class="form-container">

			{/*20220127: To avoid footer covering content: */}
			{/* <div style={{ height: '25vh' }} ></div> */}

			<form class="contact-form" method="POST" action="php_function.php" >


				<h2>CONTACT</h2>

				<input type="text" name="name" placeholder="Name" required /><br/>
				<input type="email" name="email" placeholder="Email" required /><br/>
				<input type="text" name="phone" placeholder="Phone" /><br/>
				<textarea type="text" name="message" placeholder="Message" cols="30" rows="10" required /><br/>
				<input type="submit" class="submit" value="Send & go to home page"/>

			</form>

			{/*20220127: To avoid footer covering content: */}
			{/* <div style={{ height: '20vh' }} ></div> */}

		</div>
	);

};

const UploadForm = () => {
	const [status, setStatus] = useState("Upload");

	return (

		<div class="form-container">

			<div style={{ height: '7vh' }} ></div>

			<form class="upload-form" method="POST" action="php_function_upload.php" enctype="multipart/form-data">

				<h4>UPLOAD</h4>

				<input type="text" name="name" placeholder="Name" required /><br/>
				<input type="email" name="email" placeholder="Email" required /><br/>
				<input type="text" name="phone" placeholder="Phone" /><br/>



				<input type="file" name="the_file" id="fileToUpload" required /><br/>



				<input type="submit" class="submit" value="Upload & go to home page"/>

			</form>

			{/* <div style={{ height: '20vh' }} ></div> */}

		</div>
	);
};

function Contact() {

	return(

		<div>

		<div style={{
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			height: '80vh' }}>

		<div className="grid">


		<div className="clear_Contact">
		</div>
		<div className="clear_Contact">
		</div>


		<div/><div/>
		<div/><div/>

		<ContactForm className="bio" />
		{/* <Button> Sign Up</Button> */}
		{/* <div style={{ whiteSpace: "pre-line" }}>{text}</div> */}

		<UploadForm className="bio" />


		<div/><div/>


		</div>
		</div>
		</div>

	)
}

export default Contact;

