
/* Neat intro on using href='#': https://stackoverflow.com/questions/4855168/what-is-href-and-why-is-it-used
The main use of anchor tags - <a></a> - is as hyperlinks. That basically means that they take you somewhere.
Hyperlinks require the href property, because it specifies a location.
A hash '#' within a hyperlink specifies an html element id to which the window should be scrolled.
href="#some-id" would scroll to an element on the current page such as <div id="some-id">.
href="//site.com/#some-id" would go to site.com and scroll to the id on that page.
href="#" doesn't specify an id name, but does have a corresponding location - the top of the page.
This is the expected behavior according to the w3 documentation.
I have often seen <a href="#"> so that the anchor tag is a hyperlink, but doesn't go anywhere.
Why not leave the href property blank? A blank href property is actually a hyperlink to the current page. In other words, it will cause a page refresh. As I discussed, href="#" is also a hyperlink, and causes scrolling. Therefore, the best solution for hyperlink placeholders is actually href="#!" The idea here is that there hopefully isn't an element on the page with id="!" (who does that!?) and the hyperlink therefore refers to nothing - so nothing happens.
Another question that you may be wondering is, "Why not just leave the href property off?". A common response I've heard is that the href property is required, so it "should" be present on anchors. This is FALSE! The href property is required only for an anchor to actually be a hyperlink! Read this from w3. So, why not just leave it off for placeholders? Browsers render default styles for elements and will change the default style of an anchor tag that doesn't have the href property. Instead, it will be considered like regular text. It even changes the browser's behavior regarding the element. The status bar (bottom of the screen) will not be displayed when hovering on an anchor without the href property. It is best to use a placeholder href value on an anchor to ensure it is treated as a hyperlink. */

/* css-tricks.com/the-difference-between-id-and-class: Each element can have only one ID. Each page can have only one element with that ID. */ 

export const MenuItems = [
	{
		title: 'Taxes',
		link: '/taxes',
		url: '#',
		cName: 'nav-links'
	},
	{
		title: 'Investments',
		link: '/investments',
		url: '#',		/* url will feed href in react. */
		cName: 'nav-links'	/* cName will feed className in react. */
	},
	{
		title: 'About',
		link: '/about',
		url: '#',
		cName: 'nav-links'
	},
	{
		title: 'Contact',
		link: '/contact',
		url: '#',
		cName: 'nav-links'
	}
// Later can consider 'mortgages' for mortgage financing, 'assessments', and 'wealth' for wealth management. They are somehow already implicit in Taxes and Investments.
// 	{
// 		title: 'Sign up',
// 		link: '/signups',
// 		url: '#',
// 		cName: 'nav-links-mobile'
//	}
]



