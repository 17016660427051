import  React from 'react';

import './Investments.css'



function Investments() {

	return(

		<div>

		<div style={{
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			height: '80vh' }}>

		<div className="grid">


		<div className="clear_Investments">
		</div>
		<div className="clear_Investments">
		</div>


		<div/><div/>
		<div/><div/>

		<div className="bio">

			<h2 style={{ textAlign: 'center' }}>INVESTMENTS</h2>

			<br></br>
			<p>
			BMCM Finance provides personal and institutional investment management services.
			</p>
			<br></br>
			<p>
			Our trading program is driven by perceived dynamic long term risk premiums and is fundamental in nature. Long term risk premiums are captured through exposures to asset classes, individual securities, pricing of volatility in options and efficient capital utilization through futures.
			</p>

		</div>
		
		<div className="bio">

			<br></br>
			<br></br>

			<p>
			Allocations generally contain U.S. and foreign equities, government and corporate bonds, money market instruments, options on securities, options on futures, futures, and currency futures.
			</p>
			<br></br>
			<p>
			BMCM Finance manages portfolio risk that attempts to prevent that unexpected significant market movements may derail a portfolio from capturing long term risk premiums.
			</p>
	
		</div>



		{/* 20220127: To avoid footer covering content: */}
		<div/><div/>




		</div>
		</div>


		</div>

	)
}

export default Investments;

