import  React from 'react';

import Pdf from '../BMCM_Business_Continuity_Planning_disclosure.pdf';

// import Pic from './IMG_5962.jpg';
// import Pic from './PhotoOriginal.jpg';
import Pic from './Bruno_Miranda-Edit-LR.jpg';

import './About.css'




function About() {

	return(

		<div>

		<div style={{
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			height: '80vh' }}>

		<div className="grid">


		<div className="clear">
		</div>
		<div className="clear">
		</div>





		<div/><div/>
		<div/><div/>
		<div/><div/>

		<div className="bio">

			<h2 style={{ textAlign: 'center' }}>ABOUT</h2>

			<p>
			Our mission is simple:  to create value for clients in the areas of taxes and investments.
			</p>
			<br></br>

			<p>
			The principal of BMCM Finance, Bruno Miranda, is an Enrolled Agent with the Internal Revenue Code. BMCM Finance is a registered financial advisor and commodity trading advisor.
			</p>
			<br></br>

			<p>
			Bruno Miranda is a believer that the highest quality of services focused on client financial needs requires a breadth of knowledge and experience that only a multidisciplinary approach can deliver. Bruno founded BMCM Finance under those principles. Previously, Bruno worked for more than a decade in the financial industry, in firms dedicated to investments, taxes, and banking. This followed his Ph.D. in Finance at Anderson School of Management at UCLA, a Masters in Mathematics at UCLA, and his teaching career in Macroeconomics and Microeconomics at Catholic University of Lisbon, where he graduated in Economics.
			</p>
		</div>

		<div className="photo">

			<img style={{ height:'345.6px', width:'230.4px' }} src={Pic} />

			<br></br>
			<br></br>
			<p>
			<b>Bruno Miranda, Ph.D., EA</b>
			</p>
			<p>
			Tax and Investment Advisor
			</p>
			<br></br>
			<p>
			<b><a href="tel:626.297.2109" style={{textDecoration: 'none', color: 'black'}} >626.297.2109</a></b>
			</p>
			<p>
			<b><a href="mailto:bmiranda@bmcmfinance.com" style={{textDecoration: 'none' , color: 'black' }} >bmiranda@bmcmfinance.com</a></b>
			</p>
			<br></br>

			<p>
			EA 00144255
			</p>
			<p>
			RIA CRD 314711
			</p>
			<p>
			CTA NFA 0541151
			</p>

			<br></br>
			<a href={Pdf} style={{textDecoration: 'none' , color: 'black' }} target="_blank" >Business Continuity Planning Disclosure</a>
		</div>




		{/* To avoid footer covering content: */}
		<div/><div/>




		</div>
		</div>

		</div>

	)
}

export default About;



