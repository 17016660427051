/* import React, { Component } from 'react'; */
import  React, {useState,useEffect} from 'react';


import { Link } from 'react-router-dom';



import { MenuItems } from "./MenuItems"
// import { Button } from "../Button"
import './Navbar.css'

import logo from './../../BMCM_Finance_Logo.svg'

/*
 * Let's face it, class components come with a lot of boilerplate. Constructors, binding, using "this" everywhere.
 * Using functional components removes a lot of this, so our code becomes easier to follow and maintain.
 * Since hooks let us use functional components, it means there's less code compared to class components. This makes our code more readable.*/

function Navbar(props) {

	const [clickedState,setClickedState] = useState(props.initialState);


	{/* https://www.freecodecamp.org/news/beginners-guide-to-using-react-hooks*/}
	useEffect(() => { console.log('I have just mounted!'); });
	useEffect(() => { console.log('This only runs once.'); },[]);
	useEffect(() => { console.log('Something has changed!'); },[clickedState,props.name]);
	useEffect(() => { console.log('Running effect.'); return () => {console.log('Unmounting.')}; });


	return(
		<nav className="NavbarItems">

		{/* 'fa-react' in font awesome is part of brand (fab) icons. */}
		{/* <h1 className="navbar-logo">React<i className="fab fa-react"></i></h1> */}

		{/* www.w3schools.com:/TAGS/att_alt.asp: The alt attribute provides alternative information for an image if a user for some reason cannot view it. */}
		{/* <img src={logo} className="App-logo-Nav" alt="logo" /> */}


		<Link className="Nav-logo" to={'/'}>  <img src={logo} className="Nav-logo-in" alt="logo" /> </Link>





		{/* {clickedState ? 1 : 0 } */}

		<div className="menu-icon" onClick={ () => setClickedState( clickedState ? false : true )} >			{/* Sets the opposite value. */}
			{/* 'fa-times' and 'fa-bars' are the icons from font awesome in variance solid. */}
			<i className={clickedState ? 'fas fa-times' : 'fas fa-bars'}></i>
		</div>



		<ul className={clickedState ? 'nav-menu active' : 'nav-menu'} >
			{MenuItems.map( (item,index) => {
				return (
					<li key={index}>

						{/* Instead of a html tag for hyperlink: */}




					{/* Link works well if you do not need to fully reload page for Katex: */}
					{/* <Link className={item.cName} to={item.link} >{item.title}</Link> */}

						<Link className={item.cName} to={item.link} onClick={() => setClickedState(false)} >{item.title}</Link>



					{/* Unless you need full reload use Link: */}
					{/*	<a className={item.cName} href={item.link}>{item.title}</a>	*/} 




					</li>
				)
			})}
		</ul>
		{/* <Button>Sign Up</Button> */}
		</nav>
	)
}

export default Navbar;

