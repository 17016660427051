import  React from 'react';

import './Home.css'

function Home() {
	return(


		<div>


		<div style={{
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			height: '80vh' }}>



		<div className="grid">




		<div className="clear_Home">
		</div>
		<div className="clear_Home">
		</div>





		<div/><div/>
		<div/><div/>


		<div className="bio">

			<h2 style={{ textAlign: 'center' }}>WHO WE SERVE</h2>

			<br></br>

			<p>
			Welcome, and thank you for your interest in BMCM Finance. We provide personalized tax and investment services for individuals, families, businesses, and institutions.
			</p>

			<br></br>

			<p>
			BMCM's mission is to deliver superior financial services with fine-tuned risk management while taking into account all possible outcomes. We invite you to leverage our experience, knowledge and business insight to help you succeed.
			</p>

			<br></br>

		</div>
		<div className="bio">

			<h2 style={{ textAlign: 'center' }}>HOW WE SERVE</h2>

			<br></br>

			<p>
			Knowing our clients well is the foundation to how we create value to clients. We believe that personal financial services, including tax and investment services, are what our clients deserve. We are committed to never having a client experience communicating via an automated phone service. Technology is a crucial component at BMCM, but more importantly, we uphold the value of a truly personal customer experience.
			</p>

			<br></br>

		</div>






		{/* 20220127: To avoid footer covering content: */}
		<div/><div/>







		</div>
		</div>




		</div>




	)
}

export default Home;

