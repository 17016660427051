import  React from 'react';

import './Taxes.css'

function Taxes() {
	return(

		<div>

		<div style={{
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			height: '80vh' }}>

		<div className="grid">


		<div className="clear_Taxes">
		</div>
		<div className="clear_Taxes">
		</div>







		<div/><div/>
		<div/><div/>

		<div className="bio">

			<h2 style={{ textAlign: 'center' }}>TAXES</h2>

			<br></br>

			<p>
			BMCM Finance provides tax advice to individuals and businesses, preparation and submission of tax returns, and represents individuals and companies on tax matters with the Internal Revenue Service.
			</p>

			<br></br>

			<p>
			Tax preparation services cover Individual tax returns, S-Corporations, C-Corporations, Partnerships and Trusts.
			</p>

			<br></br>

		</div>
		<div className="bio">

			<br></br>
			<br></br>
			<br></br>

			<p>
			We professionally prepare your taxes and offer insights into existing and new tax law changes that might affect your individual or business financial future. Being diligent on incorporating the latest regulations, measuring their impact on the financial future of our clients, and advise on appropriate action, is a professional commitment at BMCM.
			</p>

			<br></br>

		{/*
			<p>
			Knowing our clients well is a foundation to how we create value to clients. We believe that impersonal financial services, including tax services, are not what our clients deserve. We are committed to never having a client having to experience trying to communicate  with questions from an automated phone service. Technology is crucial at BMCM, but it always comes after the personal customer experience, never before it.
			</p>
		*/}

		</div>





		{/* To avoid footer covering content: */}
		<div/><div/>



		</div>
		</div>


		</div>

	)
}

export default Taxes;

