import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// https://reactjs.org/docs/strict-mode.html - Development tool for highlighting potential problems in an application.
// However, it can also invoke some functions twice - https://stackoverflow.com/questions/67474437/alert-appearing-twice-in-react.
/*
ReactDOM.render(	
	<React.StrictMode>
    <App />  ,
	</React.StrictMode>  ,
  document.getElementById('root')
);
*/




ReactDOM.render( <App />  , document.getElementById('root') );




/* 20220117: */





// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log)
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// reportWebVitals();
// TTFB is Time to First Byte.
reportWebVitals(console.log);

